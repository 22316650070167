import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';

// WorkCardPropsに新たに siteButtonText を追加
interface WorkCardProps {
  image: string;             // カードに表示する画像のURL
  title: string;             // カードのタイトル
  description: string;       // カードの説明文
  github_link?: string;      // GitHubリンク (省略可にする)
  site_link?: string;        // サイトへのリンク (省略可にする)
  siteButtonText?: string;   // 変更可能なボタンテキスト (省略可にする)
}

// 引数として siteButtonText も受け取り、デフォルトは "Website" とする
export default function WorkCard({
  image,
  title,
  description,
  github_link,
  site_link,
  siteButtonText = "Website"    // ここでデフォルト値を設定
}: WorkCardProps) {
  return (
    <Card
      sx={{
        maxWidth: 460,
        height: 340,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.2)'
      }}
    >
      {/* カードのトップに表示する画像 */}
      <CardMedia
        component="img"
        alt={title}
        height="165"
        image={image}
      />

      {/* カードの本文 */}
      <CardContent sx={{ overflow: 'auto' }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>

      {/* カードのフッタ部分(ボタン配置) */}
      <CardActions sx={{ marginTop: 'auto' }}>
        {/* GitHubリンクがある場合のみGitHubボタンを表示 */}
        {github_link ? (
          <Button
            variant="outlined"
            href={github_link}
            target="_blank"
            startIcon={<GitHubIcon />}
            sx={{ color: 'black', borderColor: 'black' }}
          >
            <b>GitHub</b>
          </Button>
        ) : (
          // ボタンが無い場合は高さだけ合わせるためのダミー領域
          <div style={{ height: '18px' }} />
        )}

        {/* site_link がある場合のみサイトボタンを表示。テキストは siteButtonText を使用 */}
        {site_link ? (
          <Button
            variant="outlined"
            href={site_link}
            target="_blank"
            sx={{ color: 'black', borderColor: 'black' }}
          >
           <b>{siteButtonText}</b>
          </Button>
        ) : (
          <div style={{ height: '18px' }} />
        )}
      </CardActions>
    </Card>
  );
}
