// App.js
// ポートフォリオ風の「自分ができること」セクションに加えて、
// 自分が大切にしているモットーを記載したサンプルコードです。
// 下記コードを丸ごとコピー&ペーストしてご利用ください。

import Nav from './components/Nav';
import WorkCard from './components/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Footer from './components/Footer';
import BusinessCard from './components/BizCard';

function App() {
  return (
    // 画面全体をラップするコンテナ。背景色や最低高さを指定
    <Box sx={{ color: 'black', minHeight: '100vh', bgcolor: "#fffdf6" }}>
      {/* ナビゲーションバーの表示エリア */}
      <Box mt={5}>
        <Nav />
      </Box>

      {/* Works & Projects セクションのタイトル */}
      {/* <Typography variant="h3" align="center" sx={{ mt: 5 }}>
        Works & Projects
      </Typography> */}

      {/* Works & Projects を並べるグリッドコンテナ */}
      <Grid container spacing={4} margin={5}>
        {/* ここから各種WorkCardの配置 */}
        <Grid xs={12} sm={6} md={4}>
          <BusinessCard
            name="池田 有之介 "
            title="フルスタックエンジニア/通訳(日英)/大学院生"
            email_main="連絡先: ikeda@ellipsys.co.jp"
            email=''
            phone=""
            imageUrl="logo512.png"
          />
        </Grid>

        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="card4.png"
            title="Ellipsys Inc."
            description="CTO/フルスタックエンジニアとして働いています。FastAPI/React/Mongodb/MariaDB/Dockerの技術スタックでWebアプリケーションの展開を行っています。"
            github_link=""
            site_link="https://ellipsys.co.jp/works.html"
          />
        </Grid>

        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="card2.png"
            title="一細胞解析ソフトウェアPhenoPixel4.0"
            description="顕微鏡データから細胞情報を高速に抽出し、研究員がいない夜間に全自動でデータ解析を行います。PythonでGUIを構築(TKinter)しているため、ローカルで使用可能です。"
            github_link="https://github.com/ikeda042/PhenoPixel4.0"
            site_link=""
          />
        </Grid>

        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="pheno5.png"
            title="一細胞解析API PhenoPixel5.0"
            description="PhenoPixel4.0のデータ解析をWEB上で使用できるようにAPIを展開し、SaaS化しました。計算は全てクラウドで行います。スペインの国際学会で発表された大腸菌の形態解析関連の論文の中で使用されました。"
            github_link="https://github.com/ikeda042/PhenoPixel5.0"
            site_link=""
          />
        </Grid>

        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="card5.png"
            title="HirodaiMaps"
            description="バリアフリー機能および高速な経路案内機能Rapid travelシステムを搭載したオンライン地図のPoC版を展開しました。広島大学が開催するビジネスコンテストで入賞しました。(2023年度)"
            github_link=""
            site_link="https://www.hiroshima-u.ac.jp/iagcc/news/80636"
            siteButtonText="大学公式記事"
          />
        </Grid>

        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="bussicom2.png"
            title="リアルタイム混雑度モニタの展開"
            description="Arduinoハードウェア基盤/Soracom通信モジュール/赤外線・超音波センサ/AWS EC2を用いて、カメラを使用せず学内の混雑度をリアルタイムで知ることのできるシステムを展開しました。オートエンコーダを使用して、安価なセンサシステムから高精度の人流データの取得のためのモデルを作成しました。この時、医療画像に用いる解析用深層学習ネットワークの中間層を用いることで、高精度に人流パターンをクラスタリングできることがわかり、特許申請予定です。中国地方の大学が集まるビジネスコンテストで最優秀賞を獲得しました。(2024年度)"
            github_link=""
            site_link="https://www.hiroshima-u.ac.jp/iagcc/news/87700"
            siteButtonText="大学公式記事"
          />
        </Grid>

        {/* 
        <Grid xs={12} sm={6} md={4}>
          <WorkCard 
            image="card3.png" 
            title="勉強まとめブログ" 
            description="研究や仕事で学んだことをまとめて行っている個人ブログです。月間4万PV達成。"
            github_link=""
            site_link="https://japbros-poco.main.jp/"
          />
        </Grid>
        */}

        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="reiwa.png"
            title="令和の虎プロジェクト(Native)"
            description="株式会社native.のCEO川本 寛之さんに声かけいただき、前CTOに持ち去られたToC向けコンテンツシステムを一から作り直し、初動一ヶ月で15000ユーザーを獲得しました。現在はインド人エンジニアと連携しながらバックエンドを担当しており、同時に日本人テックマネージャーとの橋渡しのための通訳も行っています。"
            github_link=""
            site_link="https://youtu.be/0sguopPrVzg?t=1157"
          />
        </Grid>
        
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="ikeda042room.png"
            title="DALL3個展"
            description="OpenAI DALL3を用いた個展サイトです。"
            github_link=""
            site_link="https://rooms.ikeda042.site/"
          />
      </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="data.png"
            title="汎用データ解析スクリプト"
            description="研究用に作成したデータ解析およびアルゴリズムのスクリプトをまとめたレポジトリです。"
            github_link="https://github.com/ikeda042/GeneralDataAnalyses"
            site_link=""
          />
        </Grid>
      </Grid>
      {/* Works & Projects セクションここまで */}

      {/* ------------------------------------------------------------- */}
      {/* ここから「自分が大切にしているモットー」セクションを追加 */}
      {/* ------------------------------------------------------------- */}
      
      {/* モットーセクションここまで */}

      {/* ------------------------------------------------------------- */}
      {/* ここから「自分ができること」セクション（Skillsセクション） */}
      {/* ------------------------------------------------------------- */}
      {/*
      <Typography variant="h3" align="center" sx={{ mt: 5 }}>
        Skills
      </Typography>
      */}

      {/* 新しいスキル紹介用のグリッドコンテナ */}
      <Grid container spacing={4} margin={5}>
        {/* スキル1: Python関連 */}
        {/*
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="python.png"
            title="Python"
            description="WEB開発(バックエンド）, データ解析, 機械学習に使用しています。WEB開発ではFastAPIによる非同期APIの開発, データ解析では研究で取得したデータの次元削減(t-SNE/PCA)や..."
            github_link=""
            site_link=""
          />
        </Grid>
        */}

        {/* スキル2: フロントエンド(React) */}
        {/*
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="react.png"
            title="React / Next.js"
            description="SPAやSSRアプリケーションの構築、状態管理やフロント設計に関する知見を持っています。"
            github_link=""
            site_link=""
          />
        </Grid>
        */}

        {/* スキル3: Docker関連 */}
        {/*
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="docker.png"
            title="Dockerコンテナ化"
            description="複数サービスのコンテナ化や本番環境へのデプロイ、CI/CDパイプラインの構築が可能です。"
            github_link=""
            site_link=""
          />
        </Grid>
        */}

        {/* スキル4: AWSなどのクラウドインフラ */}
        {/*
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="aws.png"
            title="AWS / クラウドインフラ"
            description="EC2、S3、RDS、Lambda等を使用したクラウド上でのインフラ設計・運用に対応できます。"
            github_link=""
            site_link=""
          />
        </Grid>
        */}

        {/* スキル5: データサイエンスや統計解析 */}
        {/*
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="data-analysis.png"
            title="データサイエンス / 統計解析"
            description="研究分野で培った統計学的アプローチや可視化手法を活かし、データドリブンな分析を行います。"
            github_link=""
            site_link=""
          />
        </Grid>
        */}

        {/* スキル6: 通訳(英日) */}
        {/*
        <Grid xs={12} sm={6} md={4}>
          <WorkCard
            image="translate.png"
            title="通訳(英日)"
            description="海外のエンジニアとのブリッジ業務や日本人クライアントとの調整など、英日間の通訳が可能。"
            github_link=""
            site_link=""
          />
        </Grid>
        */}
      </Grid>
      {/* 自分ができることセクションここまで */}

      {/* フッターセクション */}
      <Footer />
    </Box>
  );
}

export default App;