import React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Footer: React.FC = () => {
    return (
        <>
            <AppBar component="footer" position="static" sx={{ backgroundColor: '#000000' }}>
                <Container maxWidth="md">
                    <Box sx={{ textAlign: 'center', padding: '20px 0' }}>
                        <Typography variant="caption">
                            ©2025 ikeda042
                        </Typography>
                    </Box>
                </Container>
            </AppBar>
        </>
    );
};

export default Footer;
